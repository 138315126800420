import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(error => error)
}
import Layout from '@/layout/header/index';
const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
      path: 'index',
      name: '主页',
      component: () => import('@/views/homePage.vue')
    }
  ]
  },
  {
    path: '/honor',
    component: Layout,
    redirect: '/honor/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/honor.vue')
    }]
  },
  {
    path: '/product',
    component: Layout,
    redirect: '/product/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/product.vue')
    },
  ]
  },
  {
    path: '/product/01',
    component: Layout,
    redirect: '/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/product-01.vue')
    }]
  },
  {
    path: '/product/02',
    component: Layout,
    redirect: '/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/product-02.vue')
    }]
  },
  {
    path: '/product/03',
    component: Layout,
    redirect: '/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/product-03.vue')
    }]
  },
  {
    path: '/product/04',
    component: Layout,
    redirect: '/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/product-04.vue')
    }]
  },
  {
    path: '/contact',
    component: Layout,
    redirect: '/contact/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/contact.vue')
    }]
  },
  {
    path: '/service',
    component: Layout,
    redirect: '/service/',
    children: [{
      path: '/',
      name: '资质',
      component: () => import('@/views/service.vue')
    }]
  },
  {
    path: '/service/intro34789/',
    component:()=>import('@/views/serviceInfo.vue')
    
  },
  
]
const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
