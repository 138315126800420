<template>
  <div>
    <div class="header">
      <div class="heading_con">
        <div class="dw">
          <div class="heading">
            <div class="logo fl">
              <img alt="Vue logo" src="@/assets/logo.png">
            </div>
            <div class="c"></div>
          </div>
        </div>
      </div>
      <div class="navBar">
        <ul class="nav clearfix">
          <li id="m1" v-for="(item, index) in navbarList" :key="index" @click="currentIndex=index">
            <h3  :class="{ 'navActive': index == currentIndex }" @click="toPath(item)">{{ item.name }}</h3>
          </li>
        </ul>
      </div>
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide>
          <img src="@/assets/banner1.jpg" alt="">
        </swiper-slide>
        <swiper-slide> <img src="@/assets/banner2.jpg" alt=""></swiper-slide>
        <swiper-slide> <img src="@/assets/banner3.jpg" alt="">

        </swiper-slide>
        <swiper-slide> <img src="@/assets/banner4.jpg" alt=""></swiper-slide>
        <swiper-slide> <img src="@/assets/banner5.jpg" alt=""></swiper-slide>
      </swiper>
      <router-view></router-view>
      <div class="myfooter">

        <p>山西路瑞科贸有限公司&nbsp;©2004-2022 &nbsp;版权所有&nbsp; 地址：山西省太原市万柏林区下元街道晋祠路一段56号幸福里7幢A座1单元1层商铺1001号</p>
        <p>联系电话：0351-5613678；<a href="http://www.miibeian.gov.cn" target="_blank">晋ICP备2022003517号</a></p>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted() {
    
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          clickable: true
        },
      },
      currentIndex: 0,
      navbarList: [
        { name: '网站首页', path: '/index' },
        { name: '资质荣誉', path: '/honor' },
        { name: '产品中心', path: '/product' },
        { name: '联系我们', path: '/contact' },
        { name: '技术服务', path: '/service' },
      ]
    }
  },
  methods:{
    toPath(item){
      console.log(item);
      this.$router.push(item.path)
    }
  }
}
</script>

<style></style>